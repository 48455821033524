/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {ContactForm, Config} = _components;
  if (!Config) _missingMdxReference("Config", false);
  if (!Config.CompanyName) _missingMdxReference("Config.CompanyName", true);
  if (!ContactForm) _missingMdxReference("ContactForm", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Config.CompanyName)), " makes product customisation effortless, applying high-quality effects to text and images to create unique designs."), "\n", React.createElement(ContactForm, {
    className: "p-6 border"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
